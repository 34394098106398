import { Box, CardMedia } from "@mui/material";

interface Props {
  imgUrl: string;
  imgTitle: string;
}

const PageBannerImg = ({ imgUrl, imgTitle }: Props) => {
  return (
    <Box sx={{ padding: "2rem 0", display: { xs: "none", md: "block" } }}>
      <CardMedia
        src={imgUrl}
        alt={imgTitle}
        component="img"
        sx={{
          width: "100%",
          height: "400px",
          objectFit: "cover",
          borderRadius: "1rem",
        }}
      />
    </Box>
  );
};

export default PageBannerImg;
