import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { REVIEW_CAROUSEL_BREAKPOINTS } from "../../constants";
import { Box } from "@mui/material";
import SectionCard from "../../components/SectionCard";
import ReviewCard from "./ReviewCard";
import { Review } from "../../types/index";

const ReviewsSection = ({ reviews }: { reviews: Review[] }) => {
  return (
    <SectionCard title="Reviews" titleAlign="left">
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: {
            md: "row",
            lg: "row",
          },
          justifyContent: "space-between",
          mt: "2rem",
        }}
      >
        {reviews.map((review: Review, index) => {
          return <ReviewCard key={index} review={review} />;
        })}
      </Box>
      <Box sx={{ display: { md: "none" } }}>
        <Carousel responsive={REVIEW_CAROUSEL_BREAKPOINTS} draggable={true}>
          {reviews.map((review: Review, index) => {
            return <ReviewCard key={index} review={review} />;
          })}
        </Carousel>
      </Box>
    </SectionCard>
  );
};

export default ReviewsSection;
