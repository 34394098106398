import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  options: string[];
  handleFilter: (event: SelectChangeEvent, option: string) => void;
}

const Filter = ({ options, handleFilter }: Props) => {
  const [year, setYear] = React.useState<string>('all');

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2, padding: "0 1rem" }}>
      <FormControl sx={{width: {xs: "100%", md: "200px"}}}>
        <InputLabel id="selectLabel">Year</InputLabel>
        <Select
          labelId="selectLabel"
          id="select"
          value={year}
          label="Year"
          onChange={(e) => {
            handleFilter(e, e.target.value);
            setYear(e.target.value as string);
          }}
        >
          <MenuItem value="all">All</MenuItem>
          {options.map((option, index) => <MenuItem value={option} key={index}>{option}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

export default Filter;