import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { Record } from "../../types";

const StyledStack = styled(Stack)(({theme}) => ({
  "&": {
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    padding: "0.5rem"
  },
  "&:last-of-type": {
    borderBottom: "none",
  }, 
  "& .MuiBox-root.name p": {
    fontWeight: 800,
    minWidth: "120px",
    marginRight: "0.5rem",
  }
}));

const RecordCard = ({ record } : { record : Record }) => {
  const { name , prizes } = record;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledStack direction={isMobile ? "column" : "row"}>
      <Box className="name">
        <Typography>{name}</Typography>
      </Box>
      <Box>
        {prizes.map((prize, index) => <Typography key={index}>{prize}</Typography>)}
      </Box>
    </StyledStack>
  )
}

export default RecordCard;