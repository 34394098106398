import React, { useEffect, useRef, useState } from 'react';
import { useParams, Navigate } from "react-router-dom";
import { Container, Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Concert } from "../../types";
import { DUMMY_CONCERTS, MENUS } from "../../constants";
import { getUpcomingConcerts, getPastConcerts } from "../../http-requests";
import ConcertCard from "./ConcertCard";
import PageTitle, { PageTitlePropsType } from '../../components/PageTitle';
import PageBannerImg from '../../components/PageBannerImg';
import Filter from '../../components/Filter';
import { extractConcertYears } from "../../utils/date";


const Concerts = () => {
  let { category } = useParams();
  const [concerts, setConcerts] = useState<Concert[]>([]);
  const [concertsToRender, setConcertsToRender] = useState<Concert[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [concertYearFilterOptions, setConcertYearFilterOptions] = useState<string[]>([]);
  const imgUrl = category === "schedule" ? "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsMaybeck.jpg" : "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsParis+.jpg";
  const siblingPages = MENUS.find(menu => menu.title.toLowerCase() === "concerts")?.subMenu as PageTitlePropsType["siblingPages"];

  const handleFilter = (e: SelectChangeEvent, option: string) => {
    if(option === 'all') return setConcertsToRender(concerts);
    setConcertsToRender(concerts.filter((concert: Concert) => new Date(concert.startDate).toISOString().split('-')[0] === option));
  }

  useEffect(() => {
    const getConcertHttpRequest = async () => {
      const res = category === 'schedule' ? await getUpcomingConcerts(): await getPastConcerts();
      res ? setConcerts(Object.values(res.items)) : setConcerts(DUMMY_CONCERTS);
      setConcertYearFilterOptions(extractConcertYears(res? res.items : DUMMY_CONCERTS));
    }

    getConcertHttpRequest();
    setIsLoading(false);
  }, [getUpcomingConcerts, getPastConcerts, setConcerts, setIsLoading])

  useEffect(() => {
    setConcertsToRender(concerts);
  }, [concerts])
  
  if (category && !["schedule", "history"].includes(category)) {
    return <Navigate to="/" replace /> ;
  };

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }

  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <PageTitle title={category === 'schedule' ? "concert schedule" : "concert history"} siblingPages={siblingPages}/>
      <Box>
        <PageBannerImg imgUrl={imgUrl} imgTitle="piano concert" />
        {concertYearFilterOptions.length > 1 && <Filter options={concertYearFilterOptions} handleFilter={handleFilter}/>}
        <Box sx={{padding: "0 1rem"}}>
          {concertsToRender?.map((concert) => {
            return <ConcertCard key={concert.id} concert={concert} />;
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default React.memo(Concerts);
