import React, { useCallback } from "react";
import { Box, Button, CardMedia, Stack, Typography } from "@mui/material";
import seminarCardBg from "../../asset/seminarCardBg.png";
import { Seminar } from "../../types";
import { dateFormatter } from "../../utils/date";

export default function SeminarSectionCard({ seminar }: { seminar: Seminar }) {
  const { title, startDate, endDate, location, description, images, link } =
    seminar;
  const handleMediaClick = useCallback(
    (event: React.MouseEvent) => {
      window.open("/seminar", "_blank");
    },
    []
  );

  return (
    <Stack
      direction="row"
      sx={{
        direction: "row",
        backgroundImage: { xs: "none", md: `url(${seminarCardBg})` },
        backgroundSize: "100% 100%",
        padding: { xs: "0", md: "4rem" },
      }}
    >
      {images && <Box
        sx={{
          display: "flex",
          mt: "auto",
          mb: "auto",
          width: { xs: "100%", md: "45%" },
        }}
      >
        <CardMedia
          src={images[0]}
          component="img"
          alt={`${title}-image`}
          onClick={handleMediaClick}
        />
      </Box>}
      <Box
        sx={{
          padding: { sm: "0 0 0 2rem" },
          width: "55%",
          display: { xs: "none", md: "block" },
        }}
      >
        <Typography variant="mainH3" component="h3" mb={1}>
          {title}
        </Typography>
        <Typography variant="mainH4" component="h4" color="primary.main" mb={1}>
          {`${dateFormatter(startDate, true, true, false)} ${
            endDate ? "- " + dateFormatter(endDate, true, true, false) : ""
          }`}
        </Typography>
        <Typography variant="subtitle1" component="h4" mb={2} sx={{fontWeight: "700"}}>
          {location}
        </Typography>
        <Typography variant="body1" paragraph sx={{ whiteSpace: "pre-line" }}>
          {description.slice(0, 310)}
        </Typography>
        {link && (
          <Button
            href="/seminar"
            variant="contained"
            color="info"
            component="a"
          >
            More Details
          </Button>
        )}
      </Box>
    </Stack>
  );
}
