import { Menu } from "../types";
import { AWARDS } from "./awards";
import { HEADER_HEIGHT, FOOTER_HEIGHT, ALBUM_CAROUSEL_BREAKPOINTS, REVIEW_CAROUSEL_BREAKPOINTS } from "./styles";
import { SEMINARS, SEMINAR_SCHEDULE } from "./seminar";
import { ALBUMS } from "./albums";
import { DUMMY_YOUTUBES } from "./youtubes";
import { REVIEWS } from "./reviews";
import { DUMMY_CONCERTS } from "./concerts";
import { BIOGRAPHYTEMP } from "./biography";
import { TS_COMMISSIONS } from "./comission";
import { WRITINGS } from "./writings";
import { PHOTOS } from "./photos";
import { JOHNCAGE } from "./johncage";

export const NodeEnvironment = {
  DEV: "development",
};

export const TITLE = "THOMAS SCHULTZ, Pianist";
export const MENUS: Menu[] = [
  {
    title: "Biography",
    link: "/biography",
  },
  {
    title: "TS Piano Seminar",
    link: "/seminar",
  },
  {
    title: "Concerts",
    subMenu: [
      {
        title: "Concert Schedule",
        link: "/concerts/schedule",
      },
      {
        title: "Concert History",
        link: "/concerts/history",
      },
    ],
  },
  {
    title: "Recordings",
    subMenu: [
      {
        title: "CDs",
        link: "/recordings/cds",
      },
      {
        title: "Youtube",
        link: "/recordings/youtube",
      },
    ],
  },
  {
    title: "Reviews",
    subMenu: [
      {
        title: "Concert Reviews",
        link: "/reviews/concert",
      },
      {
        title: "Recording Reviews",
        link: "/reviews/recording",
      },
    ],
  },
  {
    title: "TSCommissions",
    link: "/tscommissions",
  },
  {
    title: "Writings",
    link: "/writings",
  },
  // {
  //   title: "John Cage",
  //   link: "/johncage",
  // },
  {
    title: "Photos",
    link: "/photos",
  },
  {
    title: "TS Studio",
    link: "/studentsawards",
  }
];
export const MONTHS = [
  "",
  "January",
  "February",
  "March",
  "Aprin",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export { 
  AWARDS,
  HEADER_HEIGHT, FOOTER_HEIGHT, ALBUM_CAROUSEL_BREAKPOINTS, REVIEW_CAROUSEL_BREAKPOINTS,
  SEMINARS, SEMINAR_SCHEDULE,
  ALBUMS,
  DUMMY_YOUTUBES,
  REVIEWS,
  DUMMY_CONCERTS,
  BIOGRAPHYTEMP,
  TS_COMMISSIONS,
  WRITINGS,
  PHOTOS,
  JOHNCAGE,
 };
