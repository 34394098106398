import React, { useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Writing } from "../../types";
import { Button } from "@mui/material";

interface WritingAccordionProps {
  writings: Writing[] | [];
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:first-of-type": {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
  },
  "&:first-of-type .MuiButtonBase-root": {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem",
  },
  "&:last-of-type .MuiCollapse-root": {
    borderBottomLeftRadius: "1rem",
    borderBottomRightRadius: "1rem",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "&.Mui-expanded": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  "&.Mui-expanded .MuiTypography-root": {
    fontWeight: "800",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
  "& .MuiAccordionSummary-content .MuiButton-root": {
    marginLeft: "2rem"
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const WritingsAccordion = ({ writings }: WritingAccordionProps) => {
  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      window.scrollTo({top: 0});
    };

  const handleClick = (link: string) => (event: React.SyntheticEvent) => {
    setExpanded(false);
    window.scrollTo({top: 0});
    window.open(link, "_blank");
  }

  return (
    <div>
      {writings.map((writing, index) => {
        const { id, title, contents, subtitle, link } = writing;
        return (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={id}
            expanded={expanded === `panel${index + 1}`}
            onChange={!subtitle && !contents && link ? handleClick(link) : handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary id={`panel${index + 1}-header`}>
              <Typography>{title}</Typography>
              {!subtitle && !contents && link && (<Button size="small">Go to the Page</Button>)}
            </AccordionSummary>
            <AccordionDetails>
              {subtitle && (
                <Typography sx={{ fontWeight: "700" }}>{subtitle}</Typography>
              )}
              <Typography sx={{ whiteSpace: "pre-line", mt: 2 }}>
                {contents}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default WritingsAccordion;
