import { useParams, Navigate } from "react-router-dom";
import Albums from "./Albums";
import Youtubes from "./Youtubes";

const Recordings = () => {
  let { category } = useParams();
  if(category === 'cds') {
    return <Albums />
  } else if(category === 'youtube') {
    return <Youtubes />
  } else {
    return <Navigate to="/" replace/>
  }
}

export default Recordings;