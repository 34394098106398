import {
  Box,
  Link,
  Typography,
} from "@mui/material";

interface Props {
  contact: string;
}

const SeminarDetails = ({contact}: Props) => {

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: {xs: "column", md: "row"}, mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: "700", mr: {md: 1} }}>
          For more info -
        </Typography>
        <Link href="mailto:schultzt@stanford.edu" sx={{ textDecoration: "none", color: "primary.dark" }}>
          {contact}
        </Link>
      </Box>
    </>
  )
}

export default SeminarDetails;