import { Photo } from "../types";

export const PHOTOS: Photo[] = [
  {
    id: "1",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/Image_euro_2.jpeg",
  },
  {
    id: "2",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/black_white_photo.gif",
  },
  {
    id: "3",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/paris_photo.jpg",
  },
  {
    id: "4",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/photo_korea.jpeg",
  },
  {
    id: "5",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsbrightlightblackwhite.jpeg",
  },
  {
    id: "6",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/cropped_tsstage.png",
  },
  {
    id: "7",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsroundstage.jpg",
  },
  {
    id: "8",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsMaybeck.jpg",
  },
  {
    id: "9",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsParis+.jpg",
  },
  {
    id: "10",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tskoret01.jpg",
  },
  {
    id: "11",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tshwaeom.jpg",
  },
  {
    id: "12",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/TSOLD2.jpg",
  },
  {
    id: "13",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/TS_keyboard.jpg",
  },
  {
    id: "14",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/TStemple2015.jpg",
  },
  {
    id: "15",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/ThomasSchultzMaple.jpg",
  },
  {
    id: "16",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsBarge.jpg",
  },
  {
    id: "17",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsLefthandUp.jpg",
  },
  {
    id: "18",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsMaybeck2016c.jpg",
  },
  {
    id: "19",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsSchoenbergHouse.jpg",
  },
  {
    id: "20",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsTalk.jpg",
  },
  {
    id: "21",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsWallSeoul.jpg",
  },
  {
    id: "22",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsmultiple.jpg",
  },
  {
    id: "23",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsbrightlight.jpeg",
  },
  {
    id: "25",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsbusan.jpg",
  },
  {
    id: "26",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tscarnegie.jpg",
  },
  {
    id: "27",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsewha.jpg",
  },
  {
    id: "28",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsewhaWall.jpg",
  },
  {
    id: "29",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tskeysblack.png",
  },
  {
    id: "30",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsmultiple3.png",
  },
  {
    id: "31",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsstage.jpeg",
  },
  {
    id: "32",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsstudio.jpg",
  },
  {
    id: "33",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/photos_with_students.jpeg",
  },
  {
    id: "34",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsinterview01.jpg",
  },
  {
    id: "35",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsmasterclass002.jpg",
  },
  {
    id: "36",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsmasterclass004.jpg",
  },
];