import { Award } from "../types";

export const AWARDS: Award[] = [
  {
    year: "2022",
    records: [
      {
        name: "Katie Liu",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music",
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Justin Wahby",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Leslie Jin",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Roger Xia",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "1st Prize, MTAC Statewide Concerto Competition"
        ]
      },
    ]
  },
  {
    year: "2021",
    records: [
      {
        name: "Linda Liu",
        prizes: [
          "1st Prize, MTAC Northern California, Solo Competition",
          "2nd Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Roger Xia",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
      {
        name: "Leslie Jin",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
    ]
  },
  {
    year: "2019",
    records: [
      {
        name: "Adrian Liu",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music",
        ]
      },
      {
        name: "Ophir Horovitz",
        prizes: [
          "1st Prize, Dorothy van Waynen Competition, Berkeley"
        ]
      },
      {
        name: "Jonathan Qi",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Joseph Yen",
        prizes: [
          "3rd Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2018",
    records: [
      {
        name: "Adrian Liu",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
        ]
      },
      {
        name: "Victor Lin",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Kevin Sun",
        prizes: [
          "1st Prize, 2018 Carmel Music Society Piano Competition",
          "Friends of Music Graduate Prize, Stanford University"
        ]
      },
      {
        name: "Aaron Wilk",
        prizes: [
          "Friends of Music Graduate Prize, Stanford University"
        ]
      },
    ]
  },
  {
    year: "2017",
    records: [
      {
        name: "Adrian Liu",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music",
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Kevin Li",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "2nd Prize, MTAC Statewide Concerto Competition"
        ]
      },
      {
        name: "Joseph Yen",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
    ]
  },
  {
    year: "2015",
    records: [
      {
        name: "Niu Niu Teo",
        prizes: [
          "1st Prize, Stanford Concerto Competition",
          "1st Prize, MTAC Northern California Concerto Competition",
          "2nd Prize, MTAC Statewide Concerto Competition"
        ]
      },
      {
        name: "Kevin Sun",
        prizes: [
          "Louis Sudler Prize in the Arts, Stanford University",
          "1st Prize, MTAC Northern California Solo Competition",
          "2nd Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Ben Mildenhall",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Sharon Kam",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
      {
        name: "Kevin Chen",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Charles de Bourcy",
        prizes: [
          "Carol and Peter Polk Graduate Music Award in Performance, Stanford University Department of Music",
          "5th Prize, Los Angeles Liszt Competition"
        ]
      },
    ]
  },
  {
    year: "2014",
    records: [
      {
        name: "Nathan Cheung",
        prizes: [
          "Baldini Prize, Mondavi Young Artist Competition",
          "Louis Sudler Prize in the Arts, Stanford University",
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Ben Mildenhall",
        prizes: [
          "Second Prize, Stanford Concerto Competition"
        ]
      },
      {
        name: "Kevin Chen",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "1st Prize, MTAC Statewide Concerto Competition"
        ]
      },
      {
        name: "Ian Zalles",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2013",
    records: [
      {
        name: "Eric Tran",
        prizes: [
          "Robert M. Golden Medal, Stanford University Department of Music"
        ]
      },
      {
        name: "Nathan Cheung",
        prizes: [
          "Peter and Carol Polk Undergraduate Award, Stanford University Department of Music"
        ]
      },
      {
        name: "Carravita Pape-Calabrese",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Ben Mildenhall",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Kevin Sun",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition",
          "Third Prize, Stanford Concerto Competition"
        ]
      },
      {
        name: "Juliann Ma",
        prizes: [
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2012",
    records: [
      {
        name: "Eric Tran",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music",
          "2nd Prize, Susan Torres Award for Pianists, Fresno Musical Club",
        ]
      },
      {
        name: "Kevin Sun",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
      {
        name: "Stephanie Wang",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2011",
    records: [
      {
        name: "Juliann Ma",
        prizes: [
          "Fulbright Grant for Piano Studies in Paris",
          "Robert M. Golden Medal, Stanford University Department of Music",
          "1st Prize, Dorothy van Waynen Competition, Berkeley"
        ]
      },
      {
        name: "Hotaik Sung",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Eric Tran",
        prizes: [
          "1st Prize, Stanford Concerto Competition",
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Nathan Cheung",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music"
        ]
      },
    ]
  },
  {
    year: "2010",
    records: [
      {
        name: "Juliann Ma",
        prizes: [
          "1st Prize, Stanford Concerto Competition"
        ]
      },
      {
        name: "Ian Counts",
        prizes: [
          "Blew-Culley-LaFolette Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Marc Evans",
        prizes: [
          "Patrick Butler Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Eric Tran",
        prizes: [
          "3rd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Brenda Ou",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Zach Weiner",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Hotaik Sung",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
      {
        name: "Krystina Tran",
        prizes: [
          "3rd Prize, MTAC Northern California Concerto Competition"
        ]
      },
    ]
  },
  {
    year: "2009",
    records: [
      {
        name: "Hotaik Sung",
        prizes: [
          "1st Prize, Stanford Concerto Competition"
        ]
      },
      {
        name: "Jack Yang",
        prizes: [
          "1st Prize, Stanford Concerto Competition"
        ]
      },
      {
        name: "Juliann Ma",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music",
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Mike Wei",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "2nd Prize, MTAC Statewide Concerto Competition"
        ]
      },
      {
        name: "Ian Counts",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Marc Evans",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Andrew Zhou",
        prizes: [
          "Honorable Mention, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2008",
    records: [
      {
        name: "Hotaik Sung",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Juliann Ma",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "1st Prize, MTAC Statewide Concerto Competition"
        ]
      },
      {
        name: "Mike Wei",
        prizes: [
          "Humanities and Sciences Undergraduate Prize in Music, Stanford University Department of Music",
          "1st Prize, MTAC Northern California Solo Competition",
          "2nd Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Maria Romanovsky",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2007",
    records: [
      {
        name: "Jessica Ou",
        prizes: [
          "Robert M. Golden Medal, Stanford University Department of Music"
        ]
      },
      {
        name: "Jack Yang",
        prizes: [
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Andrew Zhou",
        prizes: [
          "Blew-Culley-LaFollette Prize, Stanford University Department of Music",
          "2nd Prize, MTAC, Northern California Solo Competition"
        ]
      },
      {
        name: "Jonathan Aow",
        prizes: [
          "1st Prize, MTAC Northern California Concerto Competition",
          "2nd Prize, MTAC Statewide Concerto Competition",
          "2nd Prize, Dorothy van Waynen Competition, Berkeley"
        ]
      },
      {
        name: "Jennifer Vogel",
        prizes: [
          "2nd Prize, MTAC Northern California Concerto Competition"
        ]
      },
    ]
  },
  {
    year: "2006",
    records: [
      {
        name: "Yung-Yee Chen",
        prizes: [
          "1st Prize, MTAC Northern California Solo Competition",
          "1st Prize, MTAC Statewide Solo Competition"
        ]
      },
      {
        name: "Andrew Zhou",
        prizes: [
          "Humanities and Sciences Undergraduate Prize in Music, Stanford University Department of Music",
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Stephanie Le",
        prizes: [
          "Humanities and Sciences Undergraduate Prize in Music, Stanford University Department of Music"
        ]
      },
      {
        name: "Misun Hwang",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2005",
    records: [
      {
        name: "Misun Hwang",
        prizes: [
          "Dan Robinson Prize, Stanford University Department of Music"
        ]
      },
      {
        name: "Jessica Ou",
        prizes: [
          "1st Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
  {
    year: "2004",
    records: [
      {
        name: "Jeff Nguyen",
        prizes: [
          "Robert M. Golden Medal, Stanford University Department of Music"
        ]
      },
      {
        name: "David Lee",
        prizes: [
          "1st Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Jessica Ou",
        prizes: [
          "3rd Prize, MTAC Northern California Solo Competition"
        ]
      },
      {
        name: "Winnie Lai",
        prizes: [
          "2nd Prize, MTAC Northern California Solo Competition"
        ]
      },
    ]
  },
]