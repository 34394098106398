
export  const SEMINAR_WORKSHOP_OPTIONS = [
    "Ferruccio Busoni",
    "Pierre Boulez",
    "Morton Feldman",
    "Arnold Schoenberg",
    "Gyorgy Ligeti",
    "John Cage",
    "Anton Webern",
    "Frederic Rzewski",
    "Christian Wolff",
    "Hanns Eisler",
    "Louis Andriessen",
    "Toru Takemitsu",
    "Stefan Wolpe",
    "Hyo-shin Na",
    "Walter Zimmermann",
    "Karlheinz Stockhausen",
    "Olivier Messiaen",
    "Boudewijn Buckinx",
  ];

//2022 SCHEDULE
export const SEMINAR_SCHEDULE = { 
  livestreamUrl: "https://music.stanford.edu/pianoseminar2022",
  schedules: [
    {
      date: "Monday, August 15th",
      details: [
        {
          time: "12:00 - 3:00 PM",
          title: "Masterclass",
          detail: "Open to the public"
        }
      ],
    },
    {
      date: "Tuesday, August 16th",
      details: [
        {
          time: "12:00 - 3:00 PM",
          title: "Masterclass",
          detail: "Open to the public"
        }
      ],
    },
    {
      date: "Wednesday, August 17th",
      details: [
        {
          time: "1:00 - 3:00 PM",
          title: "Masterclass",
          detail: "Open to the public"
        }
      ],
    },
    {
      date: "Thursday, August 18th",
      details: [
        {
          time: "11:00 AM - 1:00 PM",
          title: "Masterclass",
          detail: "Open to the public"
        },
        {
          time: "7:30 PM",
          title: "Concert 1",
          isConcert: true,
        }
      ],
    },
    {
      date: "Friday, August 19th",
      details: [
        {
          time: "7:30 PM",
          title: "Concert 2",
          isConcert: true,
        }
      ],
    },
    {
      date: "Saturday, August 20th",
      details: [
        {
          time: "2:30 PM",
          title: "Concert 3",
          isConcert: true,
        }
      ],
    },
  ],
}

export const SEMINARS = [
  {
    id: "s2",
    startDate: "2023-08-21",
    endDate: "2023-08-26",
    location: "Campbell Recital Hall @ Braun Music Center, Stanford University ",
    address: "541 Lasuen Mall, Stanford, CA 94305", 
    title: "The Thomas Schultz Summer Piano Seminar at Stanford 2023",
    description: `The Thomas Schultz Summer Piano Seminar at Stanford is an opportunity for young pianists, eighteen and older, who are at an advanced level of study or who are at the young artist stage of their career to work with the distinguished pianist/teacher Thomas Schultz on the campus of Stanford University. The seminar will take place between August 21 and August 26, 2023. Each participant will prepare two works to play in a masterclass setting and perform in public concert. Music from both the traditional repertoire and from the more recent and rarely-heard repertoire will be studied and performed.`,
    acknowledgement: "Scholarships are available to support the costs of fees and lodging on the Stanford campus; participants will be responsible for their board.",
    link: "https://www.facebook.com/The-Thomas-Schultz-Summer-Piano-Seminar-at-Stanford-497752504087174",
    contact: "schultzt@stanford.edu",
    worksOptionsByComposer: SEMINAR_WORKSHOP_OPTIONS,
    images: [
      'https://s3.amazonaws.com/images.thomas.schultz/photos/seminar/seminarposter2023.jpg',
    ]
  },
  {
    id: "s1",
    startDate: "2022-08-15",
    endDate: "2022-08-20",
    location: "Campbell Recital Hall @ Braun Music Center, Stanford University ",
    address: "541 Lasuen Mall, Stanford, CA 94305", 
    title: "The Thomas Schultz Summer Piano Seminar at Stanford, 2022 ",
    description: `The Thomas Schultz Summer Piano Seminar is an annual opportunity for young pianists — eighteen and older, who are at an advanced level of study — to work with the distinguished pianist and pedagogue Thomas Schultz on the campus of Stanford University.`,
    acknowledgement: "The Thomas Schultz Summer Piano Seminar is sponsored by the Stanford Department of Music and supported by the Elaine and Richard Fohr Foundation.",
    images: [
      "https://s3.amazonaws.com/images.thomas.schultz/photos/seminar/pianoseminar2022.jpg",
    ],
    link: "https://www.facebook.com/The-Thomas-Schultz-Summer-Piano-Seminar-at-Stanford-497752504087174",
    contact: "schultzt@stanford.edu",
    worksOptionsByComposer: SEMINAR_WORKSHOP_OPTIONS,
    schedule: SEMINAR_SCHEDULE
  },
];
