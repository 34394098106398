import HttpRequest from './HttpRequest';

export const getAlbums = async () => {
  try {
    const response = await HttpRequest.get('/albums');
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};
