import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { REACT_APP_API_BASE_URL } from '../constants/env';

const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response.data.message)
);

const get = async (url: string, config: AxiosRequestConfig = {}) => {
  try {
    const res = await api.get(url, config);
    return res?.data;
  } catch (err) {
    console.error('...axios.get:', err);
    return null;
  }
};

export default { get };
