import {
  Box,
  Link,
  Typography,
  Grid,
} from "@mui/material";

interface Props {
  contact: string;
  seminarWorkOptions?: string[];
}

const SeminarApplicationRequirement = ({contact, seminarWorkOptions}: Props) => {
  return (
    <Box mt={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          mb: 2,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "700", mr: { md: 1 } }}>
          To apply, send the following materials to
        </Typography>
        <Link
          href={`mailto:${contact}`}
          sx={{ textDecoration: "none", color: "primary.dark" }}
        >
          {contact}
        </Link>
      </Box>
      <Box>
        <ul>
          <li>A resume of musical and academic activities</li>
          <li>
            Links to videos of performances by the applicant of two contrasting
            works
          </li>
          {seminarWorkOptions && <li>
            The titles of two works proposed for study and performance at the
            seminar:
            <ol>
              <li>A work of the applicant’s choice</li>
              <li>
                A work by a composer selected from the following list:
                <Grid
                  container
                  rowSpacing={0.5}
                  columnSpacing={3}
                  sx={{ maxWidth: "370px", mt: 2, mb: 2 }}
                >
                  {seminarWorkOptions.map((composer) => (
                    <Grid key={composer} item xs={6}>
                      {composer}
                    </Grid>
                  ))}
                </Grid>
                <Typography variant="body2">
                  * The choice of a recently-written work by a composer not on
                  this list will also be considered.
                </Typography>
              </li>
            </ol>
          </li>}
        </ul>
      </Box>
    </Box>
  );
};

export default SeminarApplicationRequirement;
