import React, { useEffect, useState } from "react";
import { WRITINGS } from "../../constants";
import { Writing } from "../../types";
import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import WritingsAccordion from "./WritingsAccordion";
import PageTitle from "../../components/PageTitle";
import PageBannerImg from "../../components/PageBannerImg";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  subtitle?: string;
}
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, subtitle } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && (
        <Box sx={{ minHeight: "30vh", p: 3, border: (t) => `solid 1px ${t.palette.lightGray.main}`, borderRadius: "0px 10px 10px 10px"}}>
          {subtitle && (
            <Typography sx={{ fontWeight: "700" }}>{subtitle}</Typography>
          )}
          <Typography sx={{ whiteSpace: "pre-line", mt: 2 }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const TAB_MARGIN = 0.5;

const Writings = () => {
  const [writings, setWritings] = useState<Writing[]>([]);
  const [value, setValue] = useState<number>(0);

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleTabClick = (e: React.SyntheticEvent, link: string) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    const activeWritings = WRITINGS.filter(
      (writing) => writing.isActive === "y"
    );
    setWritings(activeWritings);
  }, []);

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <Box>
        <PageTitle title="Writings" />
        <PageBannerImg
          imgUrl="https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsroundstage.jpg"
          imgTitle="writings"
        />
        <Box mt={3} sx={{ display: { xs: "none", md: "block" } }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiButtonBase-root": {
                  borderRadius: "10px 10px 0px 0px",
                  border: "solid 1px rgba(0, 0, 0, 0.25)",
                  borderBottom: "unset",
                  backgroundColor: "theme.palette.neutral",
                  fontSize: "16px",
                  color: "#000000",
                  maxWidth: "unset",
                  width: `calc((100% - ${TAB_MARGIN * (writings.length - 1)}rem) / ${writings.length})`
                },
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  backgroundColor: "primary.main",
                  border: (t) => `solid 1px ${t.palette.primary.main}`,
                  color: "primary.contrastText",
                  fontWeight: "800",
                },
              }}
            >
              {writings?.map((writing) => {
                const { id, title, link, contents } = writing;
                const hasExternalLink = !contents && link;
                return (
                  <Tab
                    key={id}
                    label={`${title} ${
                      hasExternalLink ? "(Opens External Link)" : ""
                    }`}
                    onClick={
                      hasExternalLink
                        ? (e) => handleTabClick(e, link)
                        : () => {}
                    }
                    sx={{
                      "&": {
                        marginRight: `${TAB_MARGIN}rem`,
                      },
                      "&:hover": {
                        backgroundColor: "primary.light",
                        borderRadius: "10px 10px 0px 0px",
                      },
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          {writings?.map((writing, index) => {
            const { id, title, subtitle, link, contents } = writing;
            const hasExternalLink = !contents && link;

            return (
              <TabPanel
                key={id}
                value={value}
                index={index}
                subtitle={subtitle}
              >
                {hasExternalLink ? `Opened External Link...` : contents}
              </TabPanel>
            );
          })}
        </Box>
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            padding: "1rem",
          }}
        >
          <WritingsAccordion writings={writings} />
        </Box>
      </Box>
    </Container>
  );
};

export default Writings;
