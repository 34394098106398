import { JohnCage } from "../types";

export const JOHNCAGE: JohnCage[] = [
  {
    id: "1",
    title: "Recordings For Mode Records",
    info: [
      "To be released 2012",
      "Thomas Schultz, piano",
      "September 14, 2012",
      "Bargemusic",
      "New York City",
    ],
    description: [
      "SOLO FOR PIANO from the Concert for Piano and Orchestra",
      "Two Pieces for Piano (1946)",
      "Two Pieces for Piano (1935)",
      "SWINGING",
    ],
  },
  {
    id: "2",
    title: "Homage to John Cage",
    info: ["September 14, 2012", "Bargemusic", "New York City"],
    description: [
      "Two Pieces for Piano (1946) John Cage (1912-1992)",
      "Solo for Piano (1957/58) (from the Concert for Piano and Orchestra) John Cage",
      "Winnsboro Cotton Mill Blues (1979) Frederic Rzewski (b. 1938)",
      "One (1987) John Cage",
      "Palais de Mari (1986) Morton Feldman (1926-1987)",
      "Near and Dear (2012) (World Premiere) Hyo-shin Na (b. 1959)",
      "Swinging (1989) John Cage",
      "Dream (1948) John Cage",
    ],
  },
  {
    id: "3",
    title: "John Cage - One Hundred Years",
    info: [
      "Concerts and Symposium",
      "October 11, 12, 2012",
      "Campbell Recital Hall,",
      "Stanford University",
    ],
    description: [
      "Thomas Schultz and the Wooden Fish Ensemble",
      "Music by John Cage, Morton Feldman, Christian Wolff, Hyo-shin Na, Walter Zimmermann",
      "Panel Discussion with Christian Wolff, Walter Zimmermann, Kathan Brown and Laura Kuhn",
    ],
    additionalInfo: [
      {
        content: "John Cage - 100 Years Poster",
        link: "Link",
      },
      {
        content: "And More",
        link: "http://news.stanford.edu/news/2012/june/john-cage-plexigrams-062712.html",
      },
    ],
  },
  {
    id: "4",
    title:
      "Works commissioned by Thomas Schultz from composers associated with Cage",
    description: [
      "Christian Wolff  Touch (2002); Long Piano (2005)",
      "Frederic Rzewski The Babble (2003)",
      "Walter Zimmermann  AIMIDE (2001-02)",
    ],
  },
  {
    id: "5",
    title:
      "Works by Cage and Related Composers in the Active Repertoire of Thomas Schultz",
    description: [
      "John Cage",
      "One",
      "selected Etudes Australes",
      "Swinging",
      "Waiting",
      "Dream",
      "For MC and DT",
      "Music for Marcel Duchamp",
      "Prelude for Meditation",
      "From Sonatas and Interludes: Gemini Sonatas",
      "\n",
      "Karlheinz Stockhausen   Klavierstück IX; Klavierstück X",
      "Pierre Boulez   First Sonata",
      "\n",
      "Morton Feldman Palais de Mari",
      "\n",
      "Luigi Nono  …sofferte onde serene",
      "\n",
      "Christian Wolff",
      "Bread and Roses",
      "Touch",
      "Long Piano (Peace March 11)",
      "\n",
      "Walter Zimmermann    Abgeschiedenheit;  AIMIDE",
      "\n",
      "Frederic Rzewski",
      "The People United Will Never Be Defeated",
      "Four",
      "Pieces for Piano",
      "North American Ballads",
      "Fantasy on Give Peace a",
      "The Babble",
    ],
  },
  {
    id: "6",
    title: "Hochschule der Künste, Berlin",
    info: ["May, 2001", "Recital  Piano Music - Cage and Beyond"],
    description: [
      "Cage Dream",
      "Lou Harrison Second Sonata",
      "Yuji Takahashi   Kwangju, May 1980",
      "Hyo-shin Na    Piano Study 2",
      "Thomas Schultz   Piano Piece",
    ],
  },
  {
    id: "7",
    title: "Schoenberg Center, Vienna",
    info: [
      "May 2001",
      "Recital at the Schoenberg Festival",
      "(Schoenberg’s Influence on American Composers)",
    ],
    description: [
      "Cage Waiting;   selected Etudes Australes",
      "Schoenberg   Six Little Piano Pieces Op.19",
      "Hyo-shin Na  Piano Study 2",
      "Frederic Rzewski   The People United Will Never Be Defeated",
    ],
  },
  {
    id: "8",
    title: "Schoenberg Center, Vienna",
    info: [
      "May 2001",
      "Recital at the Schoenberg Festival",
      "(Schoenberg’s Influence on American Composers)",
    ],
    description: [
      "Cage Waiting;   selected Etudes Australes",
      "Schoenberg   Six Little Piano Pieces Op.19",
      "Hyo-shin Na  Piano Study 2",
      "Frederic Rzewski   The People United Will Never Be Defeated",
    ],
  },
  {
    id: "9",
    title: "Old First Concerts, San Francisco",
    info: [
      "with Cage in attendance in connection with his work at Crown Point Press,",
      "San Francisco",
      "January 1992",
    ],
    description: [
      "Piano Music of John Cage",
      "Dream",
      "Swinging",
      "One",
      "selected Etudes Australes",
      "Gemini Sonatas (from Sonatas and Interludes)",
      "Music for Marcel Duchamp",
      "Prelude for Meditation",
      "Root of an Unfocus",
      "A Valentine Out of Season",
      "Christian Wolff     Bread and Roses",
      "Frederic Rzewski   Piano Pieces 3 and 4",
    ],
  },
  {
    id: "10",
    title: "University of Colorado, Boulder",
    info: ["Spring 1986"],
    description: [
      "Doctoral Thesis",
      "Indeterminacy in John Cage’s Piano Music",
    ],
  },
  {
    id: "11",
    title: "California Institute of the Arts",
    info: ["Spring 1977"],
    description: [
      "John Cage residency",
      "Performance of Cage’s Winter Music",
      "Version for 16 Pianos co-ordinated/supervised by Schultz",
    ],
  },
];
