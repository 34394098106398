import { Container, Box } from '@mui/material';
import { Award } from "../../types";
import { AWARDS } from "../../constants";
import AwardCard from "./AwardCard";
import PageTitle from '../../components/PageTitle';
import PageBannerImg from '../../components/PageBannerImg';

const imgUrl = "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsmultiple3.png";
const Awards = () => {
  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <PageTitle title={"Awards Won By Students of the TS Studio"}/>
      <Box>
        <PageBannerImg imgUrl={imgUrl} imgTitle="students awards" />
        <Box sx={{padding: {xs: "0 1rem", md: "0"}}}>
          {AWARDS?.map((award: Award, index) => <AwardCard award={award} key={index}/>)}
        </Box>
      </Box>
    </Container>
  )
}

export default Awards;