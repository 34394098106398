import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { SEMINARS } from '../../constants';
import SeminarCard from './SeminarCard';
import { Seminar } from '../../types';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: 'none',
  background: 'transparent',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  border: 'none', 
  color: theme.palette.primary.dark,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: theme.palette.primary.dark,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-content .MuiTypography-root': {
    fontWeight: '700',
    fontSize: '1.25rem',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

interface SeminarAccordionProps {
  seminars: Seminar[];
}

const SeminarAccordion = ({seminars}: SeminarAccordionProps) => {
  const latestSeminarYear = new Date(seminars[0].startDate).getFullYear().toString();
  const [expanded, setExpanded] = React.useState<string | false>(latestSeminarYear);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  
  return (
    <div>
      {seminars.map(seminar => {
        const seminarYear = new Date(seminar.startDate).getFullYear().toString();
        return (
          <Accordion key={seminarYear} expanded={expanded === seminarYear} onChange={handleChange(seminarYear)} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary aria-controls={`${seminarYear}-content`} id={`${seminarYear}-id`}>
              <Typography>{`Piano Seminar in ${seminarYear}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SeminarCard seminar={seminar}/>
            </AccordionDetails>
          </Accordion>
        )
      })}
      
    </div>
  )

}

export default SeminarAccordion;