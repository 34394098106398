import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Link } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { JohnCage } from "../../types/index";
import plusIcon from "../../asset/plusIcon.png";

const StyledPlusIcon = styled("img")`
  position: absolute;
  top: -1.5rem;
  left: 1.5rem;
`;

export interface JohnCageCardProps {
  johnCageProject: JohnCage;
}

const JohnCageCard: React.FC<JohnCageCardProps> = ({ johnCageProject }) => {
  const [isViewMore, setIsViewMore] = useState(false);
  const [descDisplayLength, setDescDisplayLength] = useState(2);

  const handleClickViewMore = () => {
    setIsViewMore((prevState) => !prevState);
  };

  useEffect(() => {
    if (isViewMore) {
      setDescDisplayLength(johnCageProject.description.length);
      return;
    }
    setDescDisplayLength(2);
  }, [isViewMore, johnCageProject.description.length]);

  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: { xs: "90%", md: "calc((100% - 3rem) / 2)" },
        backgroundColor: "transparent",
        overflow: "visible",
        mb: { xs: 6, lg: 4 },
        mx: { xs: "auto", md: "unset" },
      }}
    >
      <StyledPlusIcon
        src={plusIcon}
        alt="plus-icon"
        sx={{
          height: { xs: "2rem", md: "3rem" },
          top: {
            xs: "-1rem",
            md: "-1.5rem",
          },
          left: {
            xs: "1rem",
            md: "1.5rem",
          },
        }}
      />
      <CardContent sx={{ padding: "2rem", flexGrow: 1 }}>
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
        ></Typography>
        <Typography gutterBottom variant="mainH3" component="h3">
          {johnCageProject.title}
        </Typography>
        <Box sx={{ mb: "1rem" }}>
          {johnCageProject.info &&
            johnCageProject.info.map((info, index) => {
              return (
                <Typography
                  key={index}
                  variant="subtitle1"
                  component="div"
                  color="primary.main"
                  sx={{ fontWeight: 600 }}
                >
                  {info}
                </Typography>
              );
            })}
        </Box>
        {johnCageProject.additionalInfo && (
          <Box sx={{ mb: "1rem" }}>
            {johnCageProject.additionalInfo.map((info, index) => {
              return (
                <Box key={index} sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography sx={{ mr: "1rem" }}>{info.content}</Typography>
                  <Link
                    href={info.link}
                    target="_blank"
                    underline="none"
                    variant="subtitle1"
                    color="primary.dark"
                    sx={{ fontWeight: "700" }}
                  >
                    {info.link}
                  </Link>
                </Box>
              );
            })}
          </Box>
        )}
        {johnCageProject.description
          .slice(0, descDisplayLength)
          .map((description, index) => {
            return (
              <Typography
                key={index}
                variant="body1"
                component="div"
                sx={{ whiteSpace: "pre-line" }}
              >
                {description}
              </Typography>
            );
          })}
        {johnCageProject.description.length > 3 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              sx={{ fontSize: "0.75rem" }}
              onClick={handleClickViewMore}
            >
              {isViewMore ? (
                <>
                  <ArrowDropDownIcon sx={{ fontSize: "1rem" }} />
                  Hide More
                </>
              ) : (
                <>
                  <ArrowRightIcon sx={{ fontSize: "1rem" }} />
                  View More
                </>
              )}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default JohnCageCard;
