import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Typography, Box, CardMedia } from "@mui/material";
import { JOHNCAGE } from "../../constants";
import { JohnCage } from "../../types";
import JohnCageDetail from "./JohnCageDetail";
import JohnCageCard from "./JohnCageCard";

const JohnCageProjects = () => {
  const [johnCageProjects, setJohnCageProjects] = useState<JohnCage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentJohnCageProject, setCurrentJohnCageProject] =
    useState<JohnCage | null>(null);
  const { hash } = useLocation();

  useEffect(() => {
    setIsLoading(false);
    setJohnCageProjects(JOHNCAGE);
  }, []);

  useEffect(() => {
    if (!hash) {
      setCurrentJohnCageProject(null);
      return;
    }
    const foundProject = johnCageProjects.find(
      (johnCage) => johnCage.id === hash.split("#")[1]
    );
    if (!foundProject) {
      return;
    }
    setCurrentJohnCageProject(foundProject);
  }, [hash, johnCageProjects]);

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <Box>
        <Typography variant="pageH1" component="h1">
          John Cage
        </Typography>
      </Box>
      <Box>
        <CardMedia
          src={
            "https://s3.amazonaws.com/images.thomas.schultz/photos/johncage_banner.png"
          }
          component="img"
          width="100vw"
        />
      </Box>
      {currentJohnCageProject ? (
        <JohnCageDetail johnCageProject={currentJohnCageProject} />
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              flexDirection: {
                md: "row",
                lg: "row",
              },
              justifyContent: "space-between",
              flexWrap: "wrap",
              mt: "2rem",
            }}
          >
            {johnCageProjects.map((johnCage: JohnCage) => {
              return (
                <JohnCageCard key={johnCage.id} johnCageProject={johnCage} />
              );
            })}
          </Box>
        </>
      )}
    </Container>
  );
};

export default JohnCageProjects;
