import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { JohnCage } from "../../types";

export interface JohnCageDetailProps {
  johnCageProject: JohnCage;
}

const JohnCageDetail: React.FC<JohnCageDetailProps> = ({ johnCageProject }) => {
  return (
    <Stack
      direction={{ xs: "column", md: "column-reverse" }}
      sx={{
        margin: "2rem 0",
        padding: { xs: "1rem", md: "0" },
        border: "none",
        borderRadius: "unset",
        boxShadow: "unset",
      }}
    >
      <Box sx={{ padding: { sm: "0 1.5rem", md: 0 }, mb: "2rem" }}>
        <Typography variant="pageH3" component="h2" color="primary.main">
          {johnCageProject.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mb: "1rem",
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>
            {johnCageProject.info}
          </Typography>
        </Box>
        <Typography variant="body1">{johnCageProject.description}</Typography>
      </Box>
    </Stack>
  );
};

export default JohnCageDetail;
