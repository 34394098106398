import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  seminarDate: string;
  location: string;
  address: string;
}

const SeminarInfo = ({ title, seminarDate, location, address }: Props) => {
  return (
    <Box mb={6}>
      <Typography variant="pageH3" component="h2">
        {title}
      </Typography>
      <Typography variant="pageH3" component="h3" color="primary.main">
        {seminarDate}
      </Typography>
      <Typography
        variant="subtitle1"
        component="h3"
        sx={{ fontWeight: "700" }}
      >
        {location}
      </Typography>
      <Typography
        variant="subtitle2"
        component="h3"
        sx={{ mb: 2, fontWeight: "" }}
      >
        {address}
      </Typography>
    </Box>
  )
}

export default SeminarInfo;