import { Button, Box, Grid } from '@mui/material';
import Section from '../../components/SectionCard';
import ConcertSectionCard from './ConcertSectionCard';
import { Concert } from '../../types';

const ConcertSection = ({ concerts }: { concerts: Concert[] }) => {
  return (
    <Section title='Concert Schedule' titleAlign='center'>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row', flexWrap: 'flex-wrap', justifyContent: 'space-between' } }}>
        {concerts.map((concert) => {
          return <ConcertSectionCard key={concert.id} concert={concert} />;
        })}
      </Box>
      <Box component='div' display='flex' justifyContent='center' mt={3}>
        <Button href='/concerts/schedule' size='large' variant='contained' color='primary'>
          More Concerts Schedule
        </Button>
      </Box>
    </Section>
  );
};
export default ConcertSection;
