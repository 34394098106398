import { Box, Typography, Link } from "@mui/material";
import { SEMINAR_SCHEDULE } from "../../constants";
import { SeminarSchedule as SeminarScheduleType } from "../../types";

interface Props {
  acknowledgement: string;
  schedule?: SeminarScheduleType;
}

const SeminarSchedule = ({ acknowledgement, schedule }: Props) => {
  return (
    <Box mb={6}>
      {schedule?.livestreamUrl && (
      <Box mb={2}>
        <Typography component="span">
          {`During the seminar, each participant prepares two works to play in a masterclass setting and in a public concert, drawing from both traditional repertoire and contemporary compositions. The masterclasses presented by Thomas Schultz and three seminar participant concerts will be open to the public at Campbell Recital Hall in Braun Music Center. The three concerts will also be livestreamed on this page: `}
        </Typography>
        <Link
          href={schedule?.livestreamUrl}
          sx={{
            textDecoration: "none",
            color: "primary.dark",
          }}
        >
          {schedule?.livestreamUrl}
        </Link>
      </Box>)}
      <Box mb={4}>
        <Typography component="span">
          {acknowledgement}
        </Typography>
      </Box>
      {schedule?.schedules && (
      <Box mb={4}>
        {schedule.schedules.map(({date, details}, index) => {
          return (
            <Box mb={2} key={index}>
              <Typography sx={{ color: "primary.dark" }}>
                {date}
              </Typography>
              {details.map((detail, idx) => {
                return (
                  <Box mb={1} key={idx}>
                    <Box>
                      <Typography component="span">{`${detail.time} - `}</Typography>
                      <Typography component="span" sx={{ fontWeight: "700" }}>
                        {detail.title}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component="span">{detail.detail}</Typography>
                    </Box>
                    {detail.isConcert && (
                      <Box>
                        <Typography
                          component="span"
                          sx={{ fontStyle: "italic" }}
                        >
                          Open to the public and livestreaming on:{" "}
                        </Typography>
                        <Link
                          href={schedule.livestreamUrl}
                          sx={{
                            textDecoration: "none",
                            color: "primary.dark",
                          }}
                        >
                          {schedule.livestreamUrl}
                        </Link>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
      )}
    </Box>
  );
};

export default SeminarSchedule;
