import { useEffect, useState } from 'react';
import { Container, Typography, Box, CardMedia, Stack } from '@mui/material';
import { BIOGRAPHYTEMP } from '../../constants';
import { getBioWithReviews } from '../../http-requests';
import PageTitle from '../../components/PageTitle';
import { Review, ExtendedBio } from '../../types';
import { StayPrimaryLandscape } from '@mui/icons-material';

const Biography = () => {
  const [biographyPost, setBiographyPost] = useState<string>('');
  const [reviews, setReviews] = useState<Review[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getBioHttpRequest = async () => {
      const res: ExtendedBio | null = await getBioWithReviews();
      res ? setBiographyPost(res.description) : setBiographyPost(BIOGRAPHYTEMP);
      if (res?.bioReviews) {
        setReviews([...res.bioReviews]);
      }
    };

    getBioHttpRequest();
    setIsLoading(false);
  }, [setIsLoading, setBiographyPost, setReviews, getBioWithReviews]);

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }
  
  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <PageTitle title='Biography' />
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
        <Box
          sx={{
            pt: { xs: 0, md: 6 },
          }}>
          <CardMedia
            component='img'
            image='https://s3.amazonaws.com/images.thomas.schultz/photos/biography/ts_biography.jpeg'
            alt='Thomas Schultz biography photo'
            sx={{
              width: { xs: '100%', md: '400px' },
              filter: 'grayscale(100%)',
              borderRadius: { md: '30px' },
            }}
          />
        </Box>
        <Box
          sx={{
            p: { xs: 2, md: 4 },
            pr: { md: 0 },
          }}>
          <Typography variant='body1' color='inherit' paragraph sx={{ whiteSpace: 'pre-line' }}>
            {biographyPost.replace(/\<br\>/g, '\n')}
          </Typography>
          {reviews?.map((review, index) => {
            return (
              <Box mt={4} key={index}>
                <Typography variant="body1" color="inherit" paragraph sx={{ whiteSpace: "pre-line", fontStyle: "italic"}}>{`${review.summary}`}</Typography>
                <Typography variant="body1" paragraph sx={{color: "primary.dark", fontStyle: "italic"}}>{`${review.writer}, ${review.source}`}</Typography>
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Container>
  );
};

export default Biography;
