import SeminarSectionCard from './SeminarSectionCard';
import SectionCard from '../../components/SectionCard';
import { SEMINARS } from '../../constants';
import { Seminar } from '../../types';

const SeminarSection = ({ seminars }: { seminars: Seminar[] }) => {
  const seminar = seminars[0];

  return (
    <SectionCard title='Piano Seminar'>
      <SeminarSectionCard
        seminar={seminar}
      />
    </SectionCard>
  );
};
export default SeminarSection;
