import { Box } from "@mui/material";
import SectionCard from "../../components/SectionCard";
import YoutubeCard from "./YoutubeCard";
import { Youtube } from "../../types/index";
import { DUMMY_YOUTUBES } from "../../constants/index";

const YoutubePost = ({ youtubes }: { youtubes: Youtube[] }) => {
  return (
    <SectionCard
      title="Youtube posts"
      titleAlign="left"
      styles={{ backgroundColor: "contrastText" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: {xs: "center", sm: "unset", md: "space-between"},
        }}
      >
        {youtubes.map((youtube) => <YoutubeCard key={youtube.id} youtube={youtube} />)}
      </Box>
    </SectionCard>
  );
};

export default YoutubePost;
