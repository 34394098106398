import { Box, Container, Typography } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import { SEMINARS } from "../../constants";
import SeminarAccordion from "./SeminarAccordion";

const Seminars = () => {  
  return (
    <Container sx={{padding: {xs: "0", md: "1rem"}}}>
      <PageTitle title="The Thomas Schultz Summer Piano Seminar at Stanford" />
      <SeminarAccordion seminars={SEMINARS}/>
    </Container>
  );
};

export default Seminars;
