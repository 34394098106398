import * as React from "react";
import Container from "@mui/material/Container";
import { HEADER_HEIGHT, FOOTER_HEIGHT } from "../constants/index";

type BodyContainerProps = {
  children: React.ReactNode;
};

const BodyContainer: React.FC<BodyContainerProps> = ({ children }) => {
  return (
    <Container
      sx={{
        minHeight: {
          xs: `calc(100vh - (${HEADER_HEIGHT.xs} + ${FOOTER_HEIGHT.xs}))`,
          md: `calc(100vh - (${HEADER_HEIGHT.md} + ${FOOTER_HEIGHT.md}))`,
        },
        maxWidth: { lg: "unset" },
        width: "100vw",
        paddingLeft: { xs: 0 },
        paddingRight: { xs: 0 },
      }}
    >
      {children}
    </Container>
  );
};

export default BodyContainer;
