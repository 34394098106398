import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  Container,
  Typography,
  Box,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { PHOTOS } from "../../constants";
import { Photo } from "../../types";
import PageTitle from "../../components/PageTitle";

const Photos = () => {
  const [photos, setPhotos] = useState<Photo[]>(PHOTOS);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDownload = (imgUrl: string, id: string) => {
    fetch(imgUrl, {
      method: 'GET',
      headers: { "Cache-Control": 'no-cache' },
    })
    .then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `ts-photo-${id}`;
        alink.click();
      })
    })
    .catch(error => {
      console.log('failed to download: ', error);
    })
  };

  const handleOpenInNew = (imgUrl: string) => {
    window.open(imgUrl, "_blank");
  };

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle title="Photos" />
      <ImageList
        variant="masonry"
        sx={{
          width: "100%",
          height: "100%",
          pt: 3,
          paddingLeft: isMobile ? "16px" : "",
          paddingRight: isMobile ? "16px" : "",
        }}
        cols={isMobile ? 1 : 3}
        gap={8}
      >
        {photos?.map((photo) => (
          <ImageListItem key={photo.id} sx={{"&:hover .MuiBox-root": {display: "flex"}}}>
            <img
              src={photo.imgUrl}
              loading="lazy"
              style={{ borderRadius: "1rem" }}
            />
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                display: "none",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "rgba(255, 255, 255, 30%)",
                "& .MuiSvgIcon-root": {
                  margin: "0.5rem",
                  backgroundColor: (theme) => theme.palette.neutral.main,
                  borderRadius: "0.5rem",
                  color: "#FFF"
                }, 
                "& .MuiSvgIcon-root:hover": {
                  transform: "scale(1.1)"
                }
              }}
            >
              <DownloadIcon fontSize="large" onClick={(e) => handleDownload(photo.imgUrl, photo.id)} />
              <OpenInNewIcon fontSize="large" onClick={(e) => handleOpenInNew(photo.imgUrl)} />
            </Box>
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
};

export default Photos;
