export const ALBUMS = [
  {
    id: "rc01",
    title: "Christian Wolff - Long Piano (2004 - 2005)",
    subtitle: "Peace March 11",
    imageUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/albums/Wolff_CD.jpeg",
  },
  {
    id: "rc02",
    title: "Thomas Schultz",
    subtitle:
      "Ferrucio Busoni, Anton Webern, Hyo-shin Na, Franz Schubert, Franz Liszt",
    imageUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/albums/CD__5.jpeg",
    list: [
      {
        composer: "Ferruccio Busoni",
        title: "Berceuse",
      },
      {
        composer: "Anton Webern",
        title: "Variations, Op. 27",
      },
      {
        composer: "Hyo-shin Na",
        title: "Variations (1990)",
      },
      {
        composer: "Franz Schubert",
        title: "Drei Klavierstücke, D. 946",
      },
      {
        composer: "Franz Schubert",
        title: "Allegretto in C minor, D. 915",
      },
      {
        composer: "Franz Liszt",
        title: "Romance Oubliée",
      },
      {
        composer: "Franz Liszt",
        title: "Premiere Valse Oubliée",
      },
      {
        composer: "Franz Liszt",
        title: "Resignazione",
      },
      {
        composer: "Franz Liszt",
        title: "Les jeux d'eaux à la Villa d'Este",
      },
    ],
    musicians: [],
    publisher: "Wooden Fish Recordings",
    year: 2008,
  },
  {
    id: "rc03",
    title: "Thomas Schultz",
    subtitle: "frederic rzewski, j. s. bach",
    imageUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/albums/CD2.jpeg",
    list: [
      {
        composer: "Frederic Rzewski",
        title: "The People United Will Never Be Defeated (1975)",
      },
      {
        composer: "J. S. Bach",
        title: "Goldberg Variations",
      },
    ],
    musicians: [],
    publisher: "Wooden Fish Recordings",
    year: 2002,
  },
  {
    id: "rc04",
    title: "Thomas Schultz Piano",
    subtitle: "yuji takahashi, boudewijin buckinx, christian wolff",
    imageUrl:"https://s3.amazonaws.com/images.thomas.schultz/photos/albums/CD__4.jpeg",
    list: [
      {
        composer: "Yuji Takahashi",
        title: "For Thomas Schultz (2001)",
      },
      {
        composer: "Boudewijn Buckinx",
        title: "The Floating World (2004)",
      },
      {
        composer: "Christian Wolff",
        title: "Touch (2002)",
      },
    ],
    musicians: [],
    publisher: "Wooden Fish Recordings",
    year: 2006,
  },
  {
    id: "rc05",
    title: "Thomas Schultz",
    subtitle: "Ludwig van Beethoven",
    imageUrl:"https://s3.amazonaws.com/images.thomas.schultz/photos/albums/CD_4_22_13.jpeg",  
    list: [
      {
        composer: "Ludwig van Beethoven",
        title: "Bagatelles, Op. 126",
      },
      {
        composer: "Robert Schumann",
        title: "Waldszenen",
      },
      {
        composer: "Frederic Rzewski",
        title: "Four Pieces for Piano, Nrs. 3 and 4 (1977)",
      },
    ],
    musicians: [],
    publisher: "Wooden Fish Recordings",
    year: 2013,
  },
  {
    id: "rc06",
    title: "John Cage - The Works for Piano 10",
    imageUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/albums/cagecdcover.jpg",
    list: [
      {
        composer: "John Cage",
        title: "Two Pieces for Piano (1936)",
      },
      {
        composer: "John Cage",
        title:
          "Solo for Piano (from the Concert for Piano and Orchestra) (1958)",
      },
      {
        composer: "John Cage",
        title: "Two Pieces for Piano (1946)",
      },
      {
        composer: "John Cage",
        title: "Swinging (1989)",
      },
    ],
    musicians: [],
    publisher: "Mode Records",
    year: 2018,
  },
  {
    id: "rc07",
    title: "Wooden Fish Ensemble",
    subtitle:
      "new music from Europe and North America, traditional music from Korea and Japan",
    imageUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/albums/woodenfish_cd.jpeg",
    list: [
      {
        composer: "Hyo-shin Na",
        title: "Song of the Firewood (2010)",
      },
      {
        composer: "Hyo-shin Na",
        title: "Kayageum Song (2014)",
      },
      {
        composer: "Hyo-shin Na",
        title: "Koto, Piano (2014)",
      },
      {
        composer: "Boudewijn Buckinx",
        title: "Dreaming of Li-Po (1994)",
      },
      {
        composer: "Walter Zimmermann",
        title: "Irrgarten (1997)",
      },
      {
        composer: "Christian Wolff",
        title: "Exercise 18 (1975)",
      },
      {
        composer: "Christian Wolff",
        title: "Exercise 32 (2011)",
      },
      {
        composer: "Christian Wolff",
        title: "Burdocks/Exercise 32 (1970-71/2011)",
      },
      {
        composer: "",
        title:
          "Traditional Korean (Song of the Bellflower Roots, Song of the Roasted Chestnuts)",
      },
      {
        composer: "",
        title:
          "Traditional Japanese (Song of the Rice Harvest, Song of the Fisherman)",
      },
      {
        composer: "Frederic Rzewski",
        title: "Down By The Riverside (1979)",
      },
      {
        composer: "John Cage",
        title: "Dream (1948)",
      },
      {
        composer: "Morton Feldman",
        title: "Palais de Mari (1986)",
      },
      {
        composer: "Erik Satie",
        title: "Five Nocturnes",
      },
    ],
    musicians: [],
    publisher: "Wooden Fish Recordings",
    year: 2015,
  },
];
