import React from 'react';
import { useState, useEffect } from 'react';
import SeminarSection from './SeminarSection';
import AlbumsSection from './AlbumsSection';
import YoutubeSection from './YoutubeSection';
import ReviewsSection from './ReviewsSection';
import WelcomeSection from './WelcomeSection';
import { ALBUMS, REVIEWS, DUMMY_YOUTUBES, DUMMY_CONCERTS, SEMINARS } from '../../constants/index';
import ConcertSection from './ConcertSection';
import { Profile } from '../../types';
import { getProfile } from '../../http-requests';

const Main = () => {
  const [profile, setProfile] = useState<Profile>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProfileHttpRequest = async () => {
      const res = await getProfile();
      setProfile(res);
    };

    getProfileHttpRequest();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }
  const albums = profile.recordings?.length ? profile.recordings : ALBUMS;
  const concerts = profile.concerts?.length ? profile.concerts : DUMMY_CONCERTS;
  const reviews = profile.reviews?.length ? profile.reviews : REVIEWS;
  const seminars = profile.seminars?.length ? profile.seminars : SEMINARS;
  const youtubes = profile.youtubes?.length ? profile.youtubes : DUMMY_YOUTUBES;

  return (
    <>
      <WelcomeSection />
      <SeminarSection seminars={seminars} />
      <ConcertSection concerts={concerts} />
      <AlbumsSection albums={albums} />
      <YoutubeSection youtubes={youtubes} />
      <ReviewsSection reviews={reviews} />
    </>
  );
};

export default Main;
