import React, { useEffect } from "react";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import { Review } from "../../types";
import { dateFormatter } from "../../utils/date";
import { useNavigate, useParams } from "react-router-dom";

export interface ReviewDetailProps {
  review: Review;
  previousReviewId?: string;
  nextReviewId?: string;
}

const ReviewDetail: React.FC<ReviewDetailProps> = ({
  review,
  previousReviewId,
  nextReviewId,
}) => {
  const navigate = useNavigate();
  const { category } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0 });
    //eslint-disable-next-line
  }, []);

  return (
    <Stack
      direction={{ xs: "column", md: "column-reverse" }}
      sx={{
        margin: { xs: "0", md: "2rem 0" },
        padding: { xs: "1rem", md: "0" },
        border: "none",
        borderRadius: "unset",
        boxShadow: "unset",
      }}
    >
      <Box
        sx={{
          mb: "2rem",
          borderTop: "2px solid rgba(0, 0, 0, 0.25)",
        }}
      >
        <Typography
          variant="pageH3"
          component="h2"
          color="theme.palette.darkBlue.main"
          padding={{ xs: "0", md: "0.5rem 20px" }}
        >
          {review.title ? review.title : <span>&nbsp;&nbsp;</span>}
        </Typography>

        <Box
          sx={{
            padding: { sm: 0, md: "0.25rem 0" },
            display: "flex",
            justifyContent: "flex-start",
            mb: "1rem",
            backgroundColor: "rgba(196, 196, 196, 0.15)",
            borderTop: "2px solid rgba(0, 0, 0, 0.25)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
          }}
        >
          <Typography
            sx={{ fontWeight: 700 }}
            color="primary.main"
            padding="0 10px"
          >
            by {review.writer.replace(",", "").trim()}
            {review.date &&
              `, on ${dateFormatter(review.date, true, true, false)}`}
          </Typography>
        </Box>
        <Typography variant="body1">{review.content}</Typography>
        <br />
        <Divider />
        <Box display="flex" justifyContent="space-between" p="10px">
          <Button
            variant="outlined"
            color="info"
            size="small"
            disabled={previousReviewId ? false : true}
            onClick={() => {
              navigate(`/reviews/${category}#${previousReviewId}`);
            }}
          >
            Pre
          </Button>
          <Button
            variant="outlined"
            color="info"
            size="small"
            disabled={nextReviewId ? false : true}
            onClick={() => {
              navigate(`${nextReviewId}`);
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default ReviewDetail;
