import React, { useEffect, useState } from "react";
import { TsCommission } from "../../types";
import { TS_COMMISSIONS } from "../../constants";
import { Container, Typography, Box, Grid, List, ListItem, ListItemText } from '@mui/material';
import Commission from "./Commission";
import PageTitle from "../../components/PageTitle";
import PageBannerImg from "../../components/PageBannerImg";

const TSCommissions = () => {
  const [commissions, setCommissions] = useState<TsCommission[]>(TS_COMMISSIONS);


  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <Box>
        <PageTitle title="List of Works Commissioned by Thomas Schultz" />
        <PageBannerImg imgUrl="https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tskoret01.jpg" imgTitle="ts commissions" />
        <List>
          {commissions?.map((commission) => {
            const {composer, title, year} = commission;
            return(
              <ListItem disablePadding>
                <ListItemText primary={`${composer} - ${title} ${year ? '(' + year + ')' : ''}`} />
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Container>
  );
};

export default TSCommissions;
