import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Typography,
  styled,
} from "@mui/material";

import youtubeButton from "../../asset/youtubeButton.png";

const WelcomeSection = () => {
  const StyledYoutubeButton = styled("img")`
    position: relative;
    left: 0.5rem;
    padding: 0.3rem;
  `;

  const youtubeUrl = "/recordings/youtube";

  const welcomeImage = "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/tsbrightlight.jpeg";

  const welcomeText = "Pianist";

  const welcomeTitle = "THOMAS SCHULTZ, Pianist";

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: "300px",
        maxWidth: "1200px",
        border: "none",
        margin: "2rem auto",
        padding: { xs: 0, md: 0 },
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      > 
        <Box>
          <CardMedia
            component="img"
            image={welcomeImage}
            alt="Thomas Schultz playing the piano"
            sx={{ height: { xs: "300px", md: "480px" }, borderRadius: { md: "1rem"} }}
          />
        </Box>
        <Box
          sx={{
            position: "relative",
            titleAlign: { xs: "center", md: "left" },
            padding: {xs: "0.5rem", md: "1rem"},
            border: "none",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              fullWidth
              size="medium"
              href="/biography"
              color="primary"
              sx={{
                borderRadius: "12px",
                width: { xs: "50%", sm: "224px" },
              }}
              variant="contained"
            >
              Go to Biography
            </Button>
            <Button
              variant="text"
              startIcon={
                <StyledYoutubeButton
                  src={youtubeButton}
                  alt="youtube-icon"
                  sx={{
                    height: "3rem",
                    width: "3rem",
                  }}
                />
              }
              href={youtubeUrl}
            >
              Go to Youtube
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default WelcomeSection;
