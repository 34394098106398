import {
  Button,
  Card,
  CardActions,
  CardContent,
  ListItem,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Concert } from "../../types";
import { dateFormatter } from "../../utils/date";

const ConcertSectionCard = ({ concert }: { concert: Concert }) => {
  const {
    isActive,
    location,
    startDate,
    endDate,
    programs,
    createdAt,
    id,
    timeZone,
    title,
    entityTimestamp,
  } = concert;
  if (isActive !== "y") return <></>;

  return (
    <Card
      sx={{
        minHeight: { xs: "unset", md: "fit-content" },
        minWidth: "300px",
        width: { xs: "100%", md: "calc(100% / 3 - 1rem)" },
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        borderRadius: { xs: "0", md: "1.35rem" },
        padding: { xs: "0.5rem 0", md: "1rem" },
        borderTop: "1px solid lightGray", 
        borderLeft: {xs: "unset", md: "1px solid lightGray"},
        borderRight: {xs: "unset", md: "1px solid lightGray"},
        borderBottom: {xs: "unset", md: "1px solid lightGray"},
        "&:last-of-type": {
          borderBottom: "1px solid lightGray",
        },
        boxShadow: {
          xs: "none",
          md: "0px 2px 1px -1px rgb(0 0 0 / 30%), 0px 1px 1px 0px rgb(0 0 0 / 30%), 0px 1px 3px 0px rgb(0 0 0 / 30%)",
        },
      }}
    >
      <CardContent sx={{ padding: { xs: "0.5rem", md: "1rem" }, flexGrow: 1 }}>
        <Typography variant="mainH4" component="h3" fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="mainH4" component="h3" color="primary.dark">
          { dateFormatter(startDate, true, true, false) }
        </Typography>
        <Typography variant="body1" mb={2} fontWeight="700">
          {location}
        </Typography>
        {programs?.map((program, index) => {
          return (
            <ListItem key={index} sx={{ padding: 0, display: "list-item" }}>
              <Typography
                component="span"
                gutterBottom
              >{`${program.title} by ${program.composer}`}</Typography>
            </ListItem>
          );
        })}
      </CardContent>
      {/*TODO: IMPLEMENT MORE DETAIL FEATURE */}
      {/* <CardActions sx={{ padding: { xs: "0", md: "0.5rem" } }}>
        <Button variant="text" sx={{ fontSize: "0.75rem" }}>
          Read More <ArrowForwardIcon sx={{ fontSize: "1rem" }} />
        </Button>
      </CardActions> */}
    </Card>
  );
};
export default ConcertSectionCard;
