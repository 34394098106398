import HttpRequest from './HttpRequest';
import { Bio, ExtendedBio } from '../types';

export const getBio = async () => {
  try {
    const response: Bio = await HttpRequest.get('/bio');
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getBioWithReviews = async () => {
  try {
    const response: ExtendedBio | null = await HttpRequest.get('/bio-w-reviews');
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};
