export const getComposersLastNames = (programs: {composer: string, title: string}[]) => {
  let lastNames = new Set<string>();

  programs.forEach(program => {
    if (program.composer) {
      lastNames.add(program.composer.trim().split(' ').pop()!);
    }
  })

  return Array.from(lastNames).join(', ').replace(/, ([^,]*)$/, ', and $1');
}