//STYLE
export const HEADER_HEIGHT = { xs: "6rem", md: "10rem" };
export const FOOTER_HEIGHT = { xs: "8rem", md: "8rem" };

export const ALBUM_CAROUSEL_BREAKPOINTS = {
  xl: {
    breakpoint: { max: 3000, min: 1536 },
    items: 3,
  },
  lg: {
    breakpoint: { max: 1536, min: 1200 },
    items: 3,
  },
  md: {
    breakpoint: { max: 1200, min: 900 },
    items: 3,
  },
  sm: {
    breakpoint: { max: 900, min: 600 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

export const REVIEW_CAROUSEL_BREAKPOINTS = {
  sm: {
    breakpoint: { max: 900, min: 600 },
    items: 1,
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};
