import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Menu } from "../types";

interface MenuDrawerProps {
  menus: Menu[];
  isDrawerOpen: boolean;
  handleToggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const MenuDrawer = ({
  menus,
  isDrawerOpen,
  handleToggleDrawer,
}: MenuDrawerProps) => {
  const [accordionExpanded, setAccordionExpanded] = useState<string | false>(
    false
  );

  const handleAccordionChange =
    (panel: string, hasSubMenu: boolean) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      hasSubMenu && setAccordionExpanded(isExpanded ? panel : false);
    };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={handleToggleDrawer(false)}
      sx={{ display: { md: "none" } }}
    >
      <Box sx={{ width: 200 }} role="presentation">
        {menus.map((menu: Menu) => {
          return (
            <Accordion
              key={menu.title}
              expanded={menu.subMenu ? accordionExpanded === menu.title : false}
              onChange={handleAccordionChange(menu.title, !!menu.subMenu)}
              onClick={menu.subMenu ? () => {} : handleToggleDrawer(false)}
              square
              disableGutters
              sx={{ "&.MuiAccordion-root.Mui-expanded:before": { opacity: 1 } }}
            >
              <AccordionSummary
                expandIcon={menu.subMenu ? <ExpandMoreIcon /> : ""}
                aria-controls={`${menu.title}-content`}
                id={`${menu.title}-header`}
              >
                {menu.link && (
                  <Link
                    key={menu.title}
                    underline="none"
                    href={menu.link}
                    color="inherit"
                    sx={{width: "100%"}}
                  >
                    {menu.title}
                  </Link>
                )}
                {menu.subMenu && <Typography>{menu.title}</Typography>}
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", padding: "0" }}
              >
                {menu.subMenu &&
                  menu.subMenu.map((subMenu: Menu) => {
                    return (
                      <Link
                        key={subMenu.title}
                        href={subMenu.link}
                        underline="none"
                        color="inherit"
                        onClick={handleToggleDrawer(false)}
                        sx={{ lineHeight: 3, padding: "0 16px 0 32px" }}
                      >
                        {subMenu.title}
                      </Link>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Drawer>
  );
};

export default MenuDrawer;
