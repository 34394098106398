import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Box, CardMedia } from "@mui/material";
import { getReviews } from "../../http-requests";
import { REVIEWS, MENUS } from "../../constants";
import { Review } from "../../types";
import ReviewCard from "../Main/ReviewCard";
import ReviewListItem from "./ReviewListItem";
import ReviewDetail from "./ReviewDetail";
import PageTitle, { PageTitlePropsType } from "../../components/PageTitle";
import PageBannerImg from "../../components/PageBannerImg";

const imageUrls = [
  "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/ThomasSchultzMaple.jpg",
  "https://s3.amazonaws.com/images.thomas.schultz/photos/ts_photos/cropped_tsstage.png"
]

const Reviews = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentReview, setCurrentReview] = useState<Review | null>(null);
  const { category } = useParams();
  const { hash } = useLocation();
  const siblingPages = MENUS.find(
    (menu) => menu.title.toLowerCase() === "reviews"
  )?.subMenu as PageTitlePropsType["siblingPages"];

  useEffect(() => {
    const getBioHttpRequest = async () => {
      const res: Review[] = await getReviews();
      const reviewList = res ? res : REVIEWS;
      setReviews(reviewList.filter((review) => review.type === category));
    };

    getBioHttpRequest();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hash) {
      setCurrentReview(null);
      return;
    }
    const foundReview = reviews.find(
      (review) => review.id.split("#")[1] === hash.split("#")[1]
    );
    if (!foundReview) {
      return;
    }
    setCurrentReview(foundReview);
  }, [hash, reviews]);

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }

  let previousReviewId, nextReviewId;

  if (currentReview) {
    let index = reviews.findIndex(
      (review) => review.id.split("#")[1] === hash.split("#")[1]
    );

    let prevIndex = index > 0 ? index - 1 : -1;
    previousReviewId =
      prevIndex >= 0 ? reviews[prevIndex].id.split("REVIEW#")[1] : undefined;

    let nextIndex = index > 0 ? index + 1 : 1;
    nextReviewId =
      nextIndex >= 0 && nextIndex < reviews.length
        ? reviews[nextIndex].id.split("REVIEW")[1]
        : undefined;
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <PageTitle title={`${category} Reviews`} siblingPages={siblingPages}/>
      <PageBannerImg imgUrl={category === 'concert' ? imageUrls[0] : imageUrls[1] } imgTitle="piano concert" />
      {currentReview ? (
        <ReviewDetail
          review={currentReview}
          previousReviewId={previousReviewId}
          nextReviewId={nextReviewId}
        />
      ) : (
        <>
          <Box
            sx={{
              display: { xs: "flex", md: "flex" },
              flexDirection: {
                xs: "column",
                md: "row",
                lg: "row",
              },
              justifyContent: "space-between",
              padding: "0 1rem",
              mt: "2rem",
            }}
          >
            {reviews.slice(0, 2).map((review: Review, index) => {
              return <ReviewCard key={review.id} review={review} />;
            })}
          </Box>
          <Box sx={{ my: { xs: "1rem", md: "3rem" }, padding: "0 1rem" }}>
            {reviews.slice(2).map((review: Review) => {
              return <ReviewListItem key={review.id} review={review} />;
            })}
          </Box>
        </>
      )}
    </Container>
  );
};

export default Reviews;
