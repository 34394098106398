import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)(({ theme }) => ({
  '&': {
    minWidth: "unset",
    fontSize: "0.875rem",
  },
  '&:hover, &.active': {
    fontWeight: "700",
    backgroundColor: "unset",
  },
  '&:hover::before, &.active::before': {
    position: "absolute",
    content:"''",
    width: "1.625rem",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
    top: "85%",
  },
  '& .MuiButton-endIcon': {
    marginLeft: 0,
  }
}))