import React, { useEffect, useState } from 'react';
import { Youtube } from "../../types";
import { DUMMY_YOUTUBES, MENUS } from "../../constants";
import { Container, Box, Grid } from '@mui/material';
import YoutubeCard from "../../components/YoutubeCard";
import { getYoutubes } from '../../http-requests';
import PageTitle, { PageTitlePropsType } from '../../components/PageTitle';

const Youtubes = () => {
  const [youtubes, setYoutubes] = useState<Youtube[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const siblingPages = MENUS.find(menu => menu.title.toLowerCase() === "recordings")?.subMenu as PageTitlePropsType["siblingPages"];

  useEffect(() => {
    const getYoutubeHttpRequest = async () => {
      const res = await getYoutubes();
      (res && !!res.length) ? setYoutubes(res) : setYoutubes(DUMMY_YOUTUBES);
    }

    getYoutubeHttpRequest();
    setIsLoading(false);
  }, [setYoutubes, setIsLoading, getYoutubes])

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }
  
  return (
    <Container sx={{ padding: { xs: '0', md: '1rem' } }}>
      <Box>
        <PageTitle title="Youtube" siblingPages={siblingPages}/>
        <Grid container spacing={{xs: 2, md: 4}} sx={{padding:{ xs: "2rem 1rem", md: "2rem 0"}}}>
          {youtubes?.map((youtube) => {
            return (
                <Grid item key={youtube.id} xs={12} sm={6} lg={4} sx={{padding: "2rem 0"}}>
                  <YoutubeCard youtube={youtube} />
                </Grid>
            )
          })}
        </Grid>
      </Box>
    </Container>
  );
};

export default Youtubes;
