import { TsCommission } from "../types";

export const TS_COMMISSIONS: TsCommission[] = [
  {
    id: "1",
    title: "The Babble",
    composer: "Frederic Rzewski",
    year: "2003",
  },
  {
    id: "2",
    title: "Touch",
    composer: "Christian Wolff",
    year: "2002",
  },
   
  {
    id: "3",
    title: "Long Piano",
    composer: "Christian Wolff",
    year: "2005",
  },
  {
    id: "4",
    title: "Rain Study",
    composer: "Hyo-shin Na",
    year: "1999",
  },
  {
    id: "5",
    title: "Walking, Walking",
    composer: "Hyo-shin Na",
    publisher: {
      title: "Lantro Music - Belgium",
      link: "https://www.lantromusic.be/",
    },
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/tscommissions/walking_walking.png",
    year: "2003"
  },
  {
    id: "6",
    title: "Sea Wind",
    composer: "Hyo-shin Na",
    year: "2010",
  },
  {
    id: "7",
    title: "AIMIDE,",
    composer: "Walter Zimmermann",
    year: "2001 - 2002",
  },
  {
    id: "8",
    title: "The Floating World",
    composer: "Boudewijn Buckinx",
    publisher: {
      title: "Lantro Music - Belgium",
      link: "https://www.lantromusic.be/",
    },
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/tscommissions/the_floating_world.png",
    year: "2004",
  },
  {
    id: "9",
    title: "Romancing the World",
    composer: "Boudewijn Buckinx",
    year: "2005",
  }
];