import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Album } from "../../types";
import { ALBUMS, MENUS } from "../../constants";
import { Container, Typography, Box, Grid, Stack } from "@mui/material";
import AlbumCard from "../../components/AlbumCard";
import PageTitle, { PageTitlePropsType } from "../../components/PageTitle";
import { getAlbums } from "../../http-requests";

const Albums = () => {
  const [albums, setAlbums] = useState<Album[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const siblingPages = MENUS.find(
    (menu) => menu.title.toLowerCase() === "recordings"
  )?.subMenu as PageTitlePropsType["siblingPages"];

  useEffect(() => {
    const getAlbumHttpRequest = async () => {
      const res = await getAlbums();
      res && !!res.length ? setAlbums(res) : setAlbums(ALBUMS);
    };

    getAlbumHttpRequest();
    setIsLoading(false);
  }, [setIsLoading, setAlbums, getAlbums]);

  if (isLoading) {
    return (
      <section>
        <p>Loading ... </p>
      </section>
    );
  }

  return (
    <Container sx={{ padding: { xs: "0", md: "1rem" } }}>
      <Box>
        <PageTitle title="CDs" siblingPages={siblingPages} />
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ padding: "2rem 0" }}>
          {albums?.map((album) => {
            return (
              <Grid
                item
                key={album.id}
                xs={12}
                sm={6}
                lg={4}
                sx={{ padding: "2rem 0" }}
              >
                <AlbumCard album={album} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};

export default Albums;
