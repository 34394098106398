import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Typography,
} from "@mui/material";
import { Award } from "../../types";
import RecordCard from "./RecordCard";

const StyledCard = styled(Card)(({ theme }) => ({
  "&": {
    minHeight: "unset",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "noWrap",
    alignItems: "left",
    borderRadius: "0",
    borderTop: "0.5px solid rgb(0 0 0 / 20%)",
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    boxShadow: "none",
    backgroundColor: "unset",
    marginBottom: "2rem",
  },
  "& .MuiBox-root.year": {
    padding: "0.5rem",
    borderBottom: "0.5px solid rgb(0 0 0 / 20%)",
    backgroundColor: "rgba(196, 196, 196, 0.15)",
  },
}));

const AwardCard = ({ award }: { award: Award }) => {
  const { year, records } = award;

  return (
    <StyledCard>
      <Stack direction="column">
        <Box className="year">
          <Typography variant="pageH2">{year}</Typography>
        </Box>
        <Box className="records">
          {records.map((record, index) => <RecordCard record={record} key={index}/>)}
        </Box>
      </Stack>
    </StyledCard>
  )
};

export default AwardCard;
