import React from "react";
import { Box, Container } from "@mui/material";
import Typography from "@mui/material/Typography";

type SectionProps = {
  title: string;
  titleAlign?: "right" | "left" | "inherit" | "center" | "justify" | undefined;
  subtitle?: string;
  children: React.ReactNode;
  styles?: {
    backgroundColor: string;
  };
};

const Section = ({
  title,
  titleAlign = "center",
  subtitle,
  children,
  styles,
}: SectionProps) => {
  //TODO: OPTIONAL BUTTONS/TABS SHOULD BE IMPLEMENTED

  return (
    <Box
      sx={{
        padding: {xs: "2rem 0", md: "3rem 1rem" },
        margin: { xs: "2rem 0", md: "5rem 0" },
        border: "none",
        ...styles,
      }}
    >
      <Container>
        <Box mb={{xs: "1rem", md: "3rem"}}>
          <Typography variant="mainH2" component="h2" align={titleAlign}>
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="subtitle1"
              align={titleAlign}
              gutterBottom
              display={{ xs: "none", sm: "block" }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        {children}
      </Container>
    </Box>
  );
};
export default Section;
