import { IconButton } from "@mui/material";

type SNSButtonProps = {
  children: JSX.Element;
  IconName: string;
  url: string;
};

const SNSButton: React.FC<SNSButtonProps> = ({ children, IconName, url }) => {
  return (
    <>
      <IconButton
        aria-label={IconName}
        sx={{ color: "primary.dark" }}
        href={url}
        target="_blank"
      >
        {children}
      </IconButton>
    </>
  );
};

export default SNSButton;
