import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//styles
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
//components
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import BodyContainer from "./layouts/BodyContainer";
import mainBg from "./asset/mainBg.png";
//pages
import Main from "./pages/Main";
import Biography from "./pages/Biography";
import Seminars from "./pages/Seminars";
import Concerts from "./pages/Concerts";
import Recordings from "./pages/Recordings";
import Reviews from "./pages/Reviews";
import TSCommissions from "./pages/TSCommissions";
import Writings from "./pages/Writings";
import JohnCage from "./pages/JohnCage";
import Photos from "./pages/Photos";
import Awards from "./pages/Awards";
import { styled } from "@mui/material/styles";

const App: React.FC = () => {
  const StyledMainBg = styled("img")`
    background-image: url("${mainBg}");
    position: absolute;
    top: -4px;
    left: 0;
    z-index: -1;
  `;

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <StyledMainBg
          src={mainBg}
          sx={{ display: { xs: "none", md: "block" } }}
        />
        <Header />
        <BodyContainer>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/biography" element={<Biography />} />
              <Route path="/seminar" element={<Seminars />} />
              <Route
                path="/concerts"
                element={<Navigate to="/concerts/schedule" replace />}
              />
              <Route path="/concerts/:category" element={<Concerts />} />
              <Route path="/recordings" element={<Navigate to="/recordings/cds" replace />}/>
              <Route path="/recordings/:category" element={<Recordings />} />
              <Route
                path="/reviews"
                element={<Navigate to="/reviews/concert" replace />}
              />
              <Route path="/reviews/:category" element={<Reviews />} />
              <Route
                path="/reviews/:category/:reviewId"
                element={<Reviews />}
              />
              <Route path="/tscommissions" element={<TSCommissions />} />
              <Route path="/writings" element={<Writings />} />
              <Route path="/johncage" element={<JohnCage />} />
              <Route path="/photos" element={<Photos />} />
              <Route path="/studentsawards" element={<Awards />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </BodyContainer>
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default App;
