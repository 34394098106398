import {
  Box,
  CardMedia,
  Link,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { Seminar } from "../../types";
import { dateFormatter } from "../../utils/date";
import SeminarApplicationRequirement from "./SeminarApplicationRequirement";
import SeminarDetails from "./SeminarDetails";
import SeminarInfo from "./SeminarInfo";
import SeminarSchedule from "./SeminarSchedule";

const SeminarCard = ({ seminar }: { seminar: Seminar }) => {
  const {
    title,
    startDate,
    endDate,
    location,
    address,
    description,
    acknowledgement,
    images,
    link,
    contact,
    worksOptionsByComposer,
    schedule,
  } = seminar;

  const seminarDate = `${dateFormatter(startDate, true, true, false)} ${
    endDate ? "- " + dateFormatter(endDate, true, true, false) : ""
  }`;

  return (
    <Stack
      direction={{ xs: "column", md: "column" }}
      sx={{
        padding: { xs: "1rem", md: "0" },
        border: "none",
        borderRadius: "unset",
        boxShadow: "unset",
      }}
    >
      {images &&
        images.map((image, index) => {
          return (
            <CardMedia key={index} src={image} component="img" width="100vw" sx={{mb: 3}} />
          );
        })}
      <Box sx={{ padding: { sm: "0 1.5rem", md: "2rem 0" }, mt: "1rem", mb: "2rem", borderTop: { md: images ? "1px solid rgba(0, 0, 0, 0.12)" : "none" } }}>
        <SeminarInfo title={title} seminarDate={seminarDate} location={location} address={address}/>
        <Typography>{description}</Typography>
        <SeminarSchedule acknowledgement={acknowledgement} schedule={schedule}/>
        <SeminarDetails contact={contact} />
        <SeminarApplicationRequirement contact={contact} seminarWorkOptions={worksOptionsByComposer}/>
        <Box mt={2} mb={4}>
          {link && (
            <Link
              href={link}
              target="_blank"
              underline="none"
              variant="subtitle1"
              color="primary.dark"
              sx={{ fontWeight: "700" }}
            >
              For More Details
            </Link>
          )}
        </Box>
        <Button variant="contained" href="/biography">
          Go to Biography
        </Button>
      </Box>
    </Stack>
  );
};

export default SeminarCard;
