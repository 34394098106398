import { Review } from "../types";

export const REVIEWS: Review[] = [
  {
    id: "1",
    type: "concert",
    date: new Date("2012-09-14").toLocaleDateString(),
    writer: "Harry Rolnick",
    source: "The Classical Music Network",
    title: "Pianist Thomas Schultz at Bargemusic, New York City",
    content:
      "Thomas Schultz is one of those rare pianists who offers composers of the 20th and 21st centuries, transformng the most eccentric or aberrant notes into shimmering gemstones. Because of his repertoire, he probably doesn't have Standing Room Only audiences, but that is probably fine with him, for he gives his limited devoted an unlimited look into a specialized world",
  },
  {
    id: "2",
    type: "concert",
    date: new Date("2006-10-06").toLocaleDateString(),
    writer: "Rorianne Schrade",
    title: "Thomas Schultz, piano, Weill Recital Hall at Carnegie Hall",
    content:
      "Who on earth would offer as a piano recital’s first half three slowish Brahms pieces (the first essentially a lullaby), followed by Schoenberg’s Op. 23, a difficult premiere of a new work by Hyo-shin Na, and one of Busoni’s later and least accessible works? The answer is Thomas Schultz, and he did it superbly, before a large, rapt audience Friday, October 6.",
  },
];