import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Review } from "../../types/index";
import quoteIcon from "../../asset/quoteIcon.png";
import { dateFormatter } from "../../utils/date";

const StyledQuoteIcon = styled("img")`
  position: absolute;
  top: -1.5rem;
  left: 1.5rem;
`;

const ReviewCard = ({ review }: { review: Review }) => {
  const navigate = useNavigate();
  const { date, title, content } = review;

  const handleClickReadMore = () => {
    const reviewId = review.id.split("#")[1];
    navigate(`/reviews/${review.type}#${reviewId}`);
  };
  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        width: { md: "calc((100% - 3rem) / 2)" },
        backgroundColor: "transparent",
        overflow: "visible",
        mb: { xs: 6, lg: 0 },
      }}
    >
      <StyledQuoteIcon
        src={quoteIcon}
        alt="quote-icon"
        sx={{ display: { xs: "none", md: "unset" } }}
      />
      <CardContent sx={{ padding: "2rem", flexGrow: 1 }}>
        <Typography gutterBottom variant="subtitle1" component="div">
          {/*TODO: HANDLE DATE DATA BELOW */}
          {date && dateFormatter(date, true, true, false)}
        </Typography>
        <Typography gutterBottom variant="mainH3" component="h3">
          {title}
        </Typography>
        <Typography variant="body1" component="div">
          {/*TODO: DISCUSS THE LENGTH LIMIT OF THE CONTENT*/}
          {content.slice(0, 300)}...
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="text"
          size="small"
          sx={{ color: "primary.dark", mr: 2 }}
          onClick={handleClickReadMore}
        >
          Read More <ArrowForwardIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default ReviewCard;
