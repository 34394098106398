import { Card, CardContent, CardMedia, Box, Link, Typography } from "@mui/material";
import { Youtube as YoutubeCardProps } from "../../types/index";
import { dateFormatter } from "../../utils/date";

const YoutubeCard = ({youtube}: {youtube: YoutubeCardProps}) => {
  const { id, date, title, content, link, thumbnail, isActive } = youtube;
  if(isActive !== 'y') return <></>;

  return (
    <Card
      sx={{
        position: "relative",
        width: { xs: "100%", sm: "calc(100% / 2 - 1rem)" , md: "calc(100% / 3 - 2rem)" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none",
        backgroundColor: "transparent",
        padding: "0 0.25rem",
      }}
    >
      {thumbnail && (
        <CardMedia
          component="img"
          src={thumbnail}
          image={thumbnail}
          alt={title}
          sx={{
            width: "100%",
            height: "270px",
            borderRadius: "10px",
          }}
        ></CardMedia>
      )}
      {!thumbnail && (
        //TODO: THIS CAN BE A COMPONENT SUCH AS <IMAGEPLACEHOLDER />
        <Box
          sx={{
            width: 280,
            height: 240,
            backgroundColor: { xs: "secondary.main", lg: "primary.main" },
          }}
        />
      )}
      <CardContent sx={{padding: "1rem 0 2rem"}}>
        <Typography variant="body2" component="div">
          {date && dateFormatter(date, true, true, false)}
        </Typography>
        <Link href={link} target="_blank" sx={{textDecoration: "none", color: "inherit"}}>
          <Typography gutterBottom variant="pageH3" component="h4">
            {title}
          </Typography>
        </Link>
        {content && (
          <Typography variant="body1" component="div" color="text.secondary" sx={{display: {xs: "none", md: "block"}}}>
            {content}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default YoutubeCard;
