// TODO: update id/title/subtitle/link

export const DUMMY_YOUTUBES = [
  {
    id: "y01",
    title:
      "THOMAS SCHULTZ, pianist, plays Bach-Busoni, Rzewski, and Hyo-shin Na",
    date: new Date(2022, 3, 10).toLocaleDateString(),
    content: `THOMAS SCHULTZ, pianist, plays Bach-Busoni, Rzewski, and Hyo-shin Na 
    Wachet auf, ruft uns die Stimme & Ich ruf’ zu dir, Herr
    by J. S. Bach / Busoni`,
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube1.png",
    link: "https://www.youtube.com/watch?v=ynVCuorBF-E",
  },
  {
    id: "y02",
    title: `Beethoven - Bagatelles, Op. 126 #1
    Mark Dalrymple, recording engineer`,
    date: new Date(2013, 4, 16).toLocaleDateString(),
    content: `Thomas Schultz, piano 
    Mark Dalrymple, recording engineer`,
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube2.png",
    link: "https://www.youtube.com/watch?v=ciugLTAIwWo",
  },
  {
    id: "y03",
    title: "Bagatelle#2 Beethoven - Bagatelles, Op. 126 #2",
    imgUrl:
      "https://s3.amazonaws.com/images.thomas.schultz/photos/youtube2.png",
    date: new Date(2013, 4, 17).toLocaleDateString(),
    content: `Beehoven: Die 32 Klaviersonaten, Eroica-Variationen, Diabelli-Variationen & Sechs Bagatellen`,
    link: "https://www.youtube.com/watch?v=Z3bsyuS7s8s",
  },
];